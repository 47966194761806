import { CancelToken } from 'axios';
import { IncidentPhoneNumbers, PostTracebackIncidentRequest } from '../../interfaces/incident';
import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getIncidentApiCall = async (incidentId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/incidents/${incidentId}`
  });

export const getIncidentsApiCall = async (params: Pagination, filterElements: FilterElement) =>
  ApiRequest.request({
    method: 'POST',
    url: `/incidents/search?` + buildParams({ ...params }),
    data: filterElements
  });

export const getIncidentsSummaryApiCall = async (
  source: CancelToken,
  sourceId: number,
  incidentCampaingId: number,
  week: number,
  params: Pagination,
  transcriptFilter?: string
) =>
  ApiRequest.request({
    method: 'GET',
    url:
      `/incident-helper/summary/${sourceId}?${buildParams({
        ...params
      })}&week=${week}&incidentCampaignId=${incidentCampaingId}` +
      (transcriptFilter ? `&transcript=${encodeURIComponent(transcriptFilter)}` : ''),
    cancelToken: source
  });

export const getIncidentCampaignSourcesApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/incident-helper/sources`
  });

export const getIncidentCampaignNamesApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/incident-helper/names`
  });

export const postTracebackIncidentApiCall = async (data: PostTracebackIncidentRequest) =>
  ApiRequest.request({
    method: 'POST',
    url: `/traceback-incidents`,
    data
  });

export const updateIncidentCampaignIds = async (
  incidentCampaignId: number,
  tracebackCampaignId: number,
  requestorId: number
) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/incident-campaigns/update`,
    data: {
      incidentCampaignId,
      tracebackCampaignId,
      requestorId
    }
  });

export const ingestYouMailIncidents = async () =>
  ApiRequest.request({
    method: 'PUT',
    url: `/tasks/YouMailIngest`
  });

export const getIncidentCampaignSummaryApiCall = async (id: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/incident-helper/incident-campaign/${id}`
  });

export const checkExistingIncidentsApiCall = async (payload: IncidentPhoneNumbers[]) =>
  ApiRequest.request({
    method: 'POST',
    url: `/incidents/existingIncidents`,
    data: payload
  });
