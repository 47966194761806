import { Pagination } from 'reactstrap';
import { logoutOnAuthError } from '../../lib/apiRequest';
import {
  getHoneyBadgerAction,
  getInsightFinalScoreForProviderAction,
  getInsightWeightsAction,
  getInsightsAction,
  getInsightsChartFinalScoreAction,
  getInsightsForProviderAction,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  getChartFinalScoreApiCall,
  getHoneyBadgerhqApiCall,
  getInsightFinalScoreForProviderApiCall,
  getInsightWeightsApiCall,
  getInsightsApiCall,
  getInsightsForProviderApiCall
} from './apiCalls';

export const getInsights = (params: Pagination, filterElements: any) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getInsightsApiCall(params, filterElements);
    if (response && response.data) {
      dispatch(getInsightsAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
  dispatch(setLoadingStatus(false));
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getInsightsForProvider = (providerId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getInsightsForProviderApiCall(providerId);
    if (response && response.data) {
      dispatch(getInsightsForProviderAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
  dispatch(setLoadingStatus(false));
};

export const getInsightFinalScoreForProvider = (providerId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getInsightFinalScoreForProviderApiCall(providerId);
    if (response && response.data) {
      dispatch(getInsightFinalScoreForProviderAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
  dispatch(setLoadingStatus(false));
};

export const getInsightWeights = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getInsightWeightsApiCall();
    if (response && response.data) {
      dispatch(getInsightWeightsAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
  dispatch(setLoadingStatus(false));
};

export const getHoneyBadgerHQ = (providerId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getHoneyBadgerhqApiCall(providerId);
    if (response && response.data) {
      dispatch(getHoneyBadgerAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
  }
  dispatch(setLoadingStatus(false));
};

export const getInsightsChartFinalScore = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getChartFinalScoreApiCall();
    if (response && response.data) {
      dispatch(getInsightsChartFinalScoreAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
  dispatch(setLoadingStatus(false));
};
