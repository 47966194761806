import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DateFormat } from '../enum/DateFormat';
import {
  Campaign,
  CampaignAttributes,
  CampaignLabel,
  CampaignPostData,
  CampaignSource,
  Option,
  emptyCampaignAttributes
} from '../interfaces/campaign';
import { Comment } from '../interfaces/comment';
import { PersonalInfo } from '../interfaces/user';
import { campaignAttributes } from '../lib/campaign';
import { languageList } from '../lib/languegeSelect';
import { getClientFormattedDate } from '../lib/utilities';
import {
  addLabelObject,
  addSourceObject,
  getCampaignObject,
  setCampaign
} from '../redux/campaign/thunks';
import { stateMappings } from '../redux/stateMappings';
import CustomPopover, { IExtraProps } from './CustomPopover';
import { SelectOption } from './CustomSelect';
import CustomToolTip from './CustomToolTip';
import DropZone from './DropZone';
import BasisForTraceback from './HopDetail/BasisForTraceback';
import InputError from './inputError';
import InputFormGroup from './inputFormGroup';
import LanguageSelect from './shared/LanguageSelect';

interface IProps {
  campaign: Campaign;
  campaignServer: Function;
  getCampaignObject: Function;
  addSourceObject: Function;
  addLabelObject: Function;
  setCampaign: Function;
  createdBy: string;
  sources: SelectOption[];
  labels: CampaignLabel[];
  isView?: boolean;
  isAdd?: boolean;
  className?: string;
  attachments?: any[];
  user: PersonalInfo;
}
interface Error {
  name?: string;
  description?: string;
  comment?: string;
  reference?: string;
  source?: string;
  label?: string;
  isLive?: string;
  language?: string;
}

const CampaignEditor: React.FC<IProps> = ({
  campaign,
  campaignServer,
  getCampaignObject,
  addSourceObject,
  addLabelObject,
  setCampaign,
  createdBy,
  sources,
  labels,
  isView,
  isAdd,
  className,
  attachments,
  user
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState('');
  const [sourcesObj, setSourcesObj] = useState<CampaignSource[]>([]);
  const [labelsObj, setlabelsObj] = useState<CampaignLabel[]>([]);
  const [reference, setReference] = useState('');
  const [language, setLanguage] = useState<SelectOption>();
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [userComments, setUserComments] = useState<Comment[]>([]);
  const [attachmentsArray, setAttachmentsArray] = useState<any[]>(attachments ? attachments : []);
  const [isLive, setIsLive] = useState<boolean | null>(null);
  const [error, setError] = useState<Error>({
    name: '',
    description: '',
    comment: '',
    reference: '',
    source: '',
    label: '',
    language: ''
  });
  const [checkedState, setCheckedState] = useState(emptyCampaignAttributes());
  const labelsText = labelsObj ? labelsObj.map((label) => label.name).join(', ') : '';
  const sourcesText = sourcesObj ? sourcesObj.map((item) => item.name).join(', ') : '';
  const [allowDispute, setAllowDispute] = useState(false);

  const [campaignLiveModal, setCampaignLiveModal] = useState(false);
  const [displayLabelFraudScore, setDisplayLabelFraudScore] = useState(false);

  const editPrefix = '/campaigns/campaign/';
  useEffect(() => {
    if (location.pathname.startsWith(editPrefix)) {
      const id = Number(location.pathname.slice(editPrefix.length));
      if (!campaign || campaign.campaignId !== id) {
        getCampaignObject(id);
      }
    } else {
      setCampaign(null);
    }
  }, [location, campaign]);

  useEffect(() => {
    setCheckedState((v) => ({ ...v, isAllowDispute: allowDispute }));
  }, [allowDispute]);

  useEffect(() => {
    if (campaign && !isAdd) {
      setName(campaign.name);
      setReference(campaign.reference);
      setLanguage(languageList.find((v) => v.label == campaign.language));
      setDescription(campaign.description);
      setSourcesObj(campaign.sources);
      setlabelsObj(campaign.labels);
      setIsLive(campaign.live);
      if (campaign.campaignAttributes) {
        setCheckedState(campaign.campaignAttributes);
        setAllowDispute(campaign.campaignAttributes.isAllowDispute);
      }

      if (campaign.comments && campaign.comments.length > 0) {
        const userComments = campaign.comments.filter((item) => !item.isSystemComment);
        setUserComments(userComments);
      }
    } else {
      setName('');
      setReference('');
      setLanguage(languageList.find((v) => v.value == 'en'));
      setDescription('');
      setSourcesObj([]);
      setlabelsObj([]);
      setIsLive(null);
      setCheckedState(emptyCampaignAttributes());
      setAllowDispute(false);
      setUserComments([]);
    }
  }, [campaign, isAdd]);

  useEffect(() => {
    setError((v) => ({ ...v, isLive: '' }));
  }, [isLive]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.name !== 'low_volume') {
      event.preventDefault();
    }

    const { name, value } = event.currentTarget;

    switch (name) {
      case 'name': {
        setName(value);
        setError({ ...error, name: '' });
        break;
      }
      case 'reference': {
        setReference(value);
        setError({ ...error, reference: '' });
        break;
      }
      case 'description': {
        setDescription(value);
        setError({ ...error, description: '' });
        break;
      }
      case 'comment': {
        setComment(value);
        setError({ ...error, comment: '' });
        break;
      }
      default:
        break;
    }
  };
  const handleAttributes = (event: React.FormEvent<HTMLInputElement>, tag: string) => {
    if (event.currentTarget.name !== 'attributes') {
      event.preventDefault();
    }
    const { checked } = event.currentTarget;
    let newCheckedState = checkedState;
    newCheckedState[tag as keyof CampaignAttributes] = checked;
    setCheckedState(newCheckedState);
    const count = Object.entries(newCheckedState).filter(
      ([key, value]) => key != 'isAllowDispute' && value
    ).length;
    setAllowDispute((v) => (count ? (checked && count === 1 ? true : v) : false));
  };
  const getErrorMessages = () => {
    let errorObj: Error = {};
    if (name === '') {
      errorObj.name = 'Please provide a valid campaign name.';
    }
    if (sourcesObj.length === 0) {
      errorObj.source = 'Please provide a source.';
    }
    if (description === '') {
      errorObj.description = 'Please provide a description.';
    }
    if (isLive === null) {
      errorObj.isLive = 'Please select campaign volume.';
    }
    if (!language) {
      errorObj.language = 'Please select a language.';
    }
    setError({ ...errorObj });
    return Object.values(errorObj).length;
  };

  const handleCancelClick = (e: any) => {
    e.preventDefault();
    if (isAdd) {
      if (!history) return;
      navigate('/campaigns');
    } else {
      window.location.reload();
    }
  };

  const addFile = (files: File[]) => {
    setAttachmentsArray([
      ...attachmentsArray,
      ...files.filter((file) => !attachmentsArray.find((item) => item.name === file.name))
    ]);
  };

  const removeFile = (file: any) => {
    const attachments = attachmentsArray.filter((item) => item.name !== file.name);
    setAttachmentsArray(attachments);
  };

  const setOptions = (options: Option[]) => {
    const sources: CampaignSource[] = options.map((option) => {
      return {
        sourceId: option.value,
        name: option.label,
        isSystemDefined: true,
        isProvider: true
      };
    });

    let source = '';
    sources.forEach((item, index) => {
      source += item.name;
      if (index !== sources.length - 1) {
        source += ', ';
      }
    });

    if (sources) {
      error.source = '';
    }
    setSourcesObj(sources);
    setError(error);
  };

  const setLabelOptions = (options: Option[]) => {
    const labels = options.map((option) => {
      return {
        labelID: option.value,
        name: option.label
      };
    });
    setlabelsObj(labels);
  };

  const addOrRemoveSource = (option: Option) => {
    const options = sourcesObj.map((item) => item.sourceId);
    const sources = options.includes(option.value)
      ? sourcesObj.filter((source) => source.sourceId !== option.value)
      : [
          ...sourcesObj,
          {
            sourceId: option.value,
            name: option.label,
            isSystemDefined: true,
            isProvider: true
          }
        ];
    let source = '';
    sources.forEach((item, index) => {
      source += item.name;
      if (index !== sources.length - 1) {
        source += ', ';
      }
    });

    if (sources) {
      error.source = '';
    }
    setSourcesObj(sources);
    setError(error);
  };

  const addOrRemoveLabel = (option: Option) => {
    const options = labelsObj.map((item) => item.labelID);

    const labels = options.includes(option.value)
      ? labelsObj.filter((labelTmp) => labelTmp.labelID !== option.value)
      : [
          ...labelsObj,
          {
            labelID: option.value,
            name: option.label
          }
        ];

    if (labels) {
      error.label = '';
    }
    setlabelsObj(labels);
    setError(error);
  };

  const toggleCampaignLiveModal = () => {
    setCampaignLiveModal((v) => !v);
  };
  const submitForm = async (e: any, needsCampaignLiveModal: boolean) => {
    e.preventDefault();
    if (getErrorMessages()) {
      return;
    }
    if (needsCampaignLiveModal && !isAdd && campaign && isLive != campaign.live) {
      setCampaignLiveModal(true);
      return;
    }
    let campaignObject: CampaignPostData = {
      campaignId: campaign && campaign.campaignId,
      name,
      source: sourcesText,
      sources: sourcesObj,
      labels: labelsObj,
      reference,
      language: language?.label || null,
      workflow: '',
      description,
      createdBy,
      live: !!isLive,
      campaignAttributes: checkedState
    };
    if (isAdd) {
      campaignObject.create_date = new Date().toUTCString();
      campaignObject.update_date = new Date().toUTCString();
    }
    await campaignServer(campaignObject, comment, attachmentsArray);
  };

  return (
    <Fragment>
      <Form
        className={`form-campaign ${className}`}
        style={isView ? { borderColor: 'transparent', background: 'transparent' } : {}}
      >
        <label className="label-bold">
          Campaign Name{!isView && <i className="fa fa-asterisk asterisk" />}
        </label>
        <InputFormGroup
          isReadonly={isView}
          inputName="name"
          inputId="name"
          inputClassName="input-campaign col-lg-10"
          inputValue={name}
          inputOnChange={handleChange}
          inputPlaceholder=""
          inputAutoComplete="off"
          inputErrorClassName="col-lg-10"
          errorMessage={error.name}
        />
        <label className="label-bold">
          Source{!isView && <i className="fa fa-asterisk asterisk" />}
        </label>
        {isView && (
          <FormGroup>
            <p className="telecom-text mb-0 ms-2">
              {sourcesText.replace(/[;][\s]$/, ' ') || 'None'}
            </p>
          </FormGroup>
        )}
        {!isView && (
          <FormGroup>
            {sourcesObj && sourcesObj.length === 0 && (
              <p className="telecom-text mb-0">Select the source for this campaign</p>
            )}
            <CustomPopover
              id="add-sources"
              buttonText="Add Source"
              popoverHeaderMessage="Select one or more sources"
              popoverAddButtonMessage="+ Add new source"
              options={sources}
              setOptions={setOptions}
              activeOptions={(sourcesObj || []).map((item) => {
                return {
                  value: item.sourceId,
                  label: item.name
                };
              })}
              addOrRemoveOption={addOrRemoveSource}
              optionServer={(opt) => {
                addSourceObject(opt);
                return true;
              }}
            />
            {error.source && <InputError>{error.source}</InputError>}
          </FormGroup>
        )}
        <label className="label-bold">Labels</label>
        {isView && (
          <FormGroup>
            <p className="telecom-text mb-0 ms-2">
              {labelsText.replace(/[;][\s]$/, ' ') || 'None'}
            </p>
          </FormGroup>
        )}
        {!isView && (
          <FormGroup>
            {labelsObj && labelsObj.length === 0 && (
              <p className="telecom-text mb-0">Select campaign labels</p>
            )}
            <CustomPopover
              id="add-labels"
              buttonText="Add Label"
              popoverHeaderMessage="Select one or more labels"
              popoverAddButtonMessage="+ Add new label"
              options={(labels || []).map((item) => {
                return {
                  value: item.labelID,
                  label: item.name
                };
              })}
              setOptions={setLabelOptions}
              activeOptions={(labelsObj || []).map((item) => {
                return {
                  value: item.labelID,
                  label: item.name
                };
              })}
              addOrRemoveOption={addOrRemoveLabel}
              optionServer={(opt: string, score?: number) => {
                setDisplayLabelFraudScore(!score);
                if (!score) return false;
                addLabelObject(opt, score);
                return true;
              }}
              inputPlaceholder="Label Name"
              extraInputsComponent={({ value, setValue }: IExtraProps<number>) => {
                const handleFraudScore = (e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  const value = e.target.value;
                  const pattern = /^[1-5]$/;
                  return pattern.test(value) ? setValue(Number(value)) : setValue(0);
                };
                return (
                  <Fragment>
                    <div className="row mb-2">
                      <div className="col-7">
                        <label>Label Fraud Score</label>
                      </div>
                      <div className="col-4">
                        <input
                          value={value || ''}
                          onChange={(e) => handleFraudScore(e)}
                          type="number"
                          className="comments-search-with-border"
                          min="1"
                          max="5"
                        />
                      </div>
                    </div>
                    {!value && displayLabelFraudScore && (
                      <InputError className="telecom-input-error">
                        Please provide a fraud score
                      </InputError>
                    )}
                  </Fragment>
                );
              }}
            />
            {error.label && <InputError>{error.label}</InputError>}
          </FormGroup>
        )}
        <label className="label-bold">Reference</label>
        <InputFormGroup
          isReadonly={isView}
          inputName="reference"
          inputId="reference"
          inputClassName="input-campaign col-lg-10"
          inputValue={reference}
          inputOnChange={handleChange}
          inputPlaceholder=""
          inputAutoComplete="off"
          errorMessage={error.reference}
        />
        <label className="label-bold">
          Language {!isView && <i className="fa fa-asterisk asterisk" />}
        </label>
        {isView ? (
          <FormGroup>
            <p className="telecom-text mb-0 ms-2">{language?.label || 'None'}</p>
          </FormGroup>
        ) : (
          <Fragment>
            <LanguageSelect
              typeLabel="customselect-small language"
              selectClass="mb-2"
              value={language}
              onChange={(item: OnChangeValue<SelectOption, boolean>) => {
                setLanguage(item as SelectOption);
                if (item) setError((v) => ({ ...v, language: '' }));
              }}
            />
            {error.language && <InputError>{error.language}</InputError>}
          </Fragment>
        )}
        <label className="label-bold">
          Description{!isView && <i className="fa fa-asterisk asterisk" />}
        </label>
        <InputFormGroup
          isReadonly={isView}
          isTextarea
          inputName="description"
          inputId="description"
          inputClassName="input-textarea"
          inputValue={description}
          inputOnChange={handleChange}
          inputPlaceholder="Describe workflow. e.g., Search on called number only
                                        if you cannot search using non-NANP calling number,
                                        or describe the campaign more generally."
          inputAutoComplete="off"
          errorMessage={error.description}
        />

        <FormGroup>
          <label id="comments" className="label-bold">
            Comments
          </label>
          {isView &&
            (userComments && userComments.length > 0 ? (
              userComments.map((item: Comment) => {
                return (
                  <div key={item.commentId}>
                    <div className="row ps-2">
                      <div className="col-7">
                        <span className="fw-bold">{`Date/Time: `}</span>
                        {getClientFormattedDate(item.create_date, DateFormat.MediumBoth)}
                      </div>
                      <div className="col-5">
                        <span className="fw-bold">{`From: `}</span>
                        {item.userName}
                      </div>
                    </div>
                    <div className="row ps-2">
                      <div className="col-12">{item.contentText}</div>
                    </div>
                    <div className="row ps-2 mb-2">
                      <div className="col-12">
                        <span className="fw-bold">{`Attachments: `}</span>
                        {item.attachments && item.attachments.length > 0 ? (
                          item.attachments.map((attachment: any) => (
                            <a
                              className="fw-bold"
                              key={attachment.attachmentId}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`/api/attachments/${attachment.attachmentId}`}
                            >
                              {attachment.fileName}
                            </a>
                          ))
                        ) : (
                          <span>None</span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="telecom-text mb-0 ps-2">None</p>
            ))}
          {!isView && (
            <Fragment>
              <InputFormGroup
                inputName="comment"
                inputId="comment"
                inputClassName="input-campaign"
                inputValue={comment}
                inputPlaceholder="Add any comments"
                inputAutoComplete="off"
                inputOnChange={handleChange}
                errorMessage={error.comment}
              />
            </Fragment>
          )}
          {isView && (
            <Fragment>
              <label className="label-bold mt-2">Live or Undetermined</label>
              <p className="telecom-text mb-3 ps-2">{isLive ? 'True' : 'False'}</p>
              <BasisForTraceback source={campaign.campaignAttributes} isCampaign user={user} />
            </Fragment>
          )}
          {!isView && (
            <Fragment>
              <div
                className={`d-flex justify-content-center mt-1 ${!error.isLive ? '' : 'border border-danger'}`}
              >
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    value="low_volume"
                    name="low_volume_choice"
                    className="form-check-input"
                    checked={isLive === true}
                    onChange={() => {
                      setIsLive(true);
                    }}
                  />
                  <label className="label-bold" htmlFor="low_volume">
                    Live or Undetermined Campaign
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    value="high_volume"
                    name="high_volume-choice"
                    className="form-check-input"
                    checked={isLive === false}
                    onChange={() => {
                      setIsLive(false);
                    }}
                  />
                  <label className="label-bold" htmlFor="high_volume">
                    Prerecorded or Artificial Campaign
                  </label>
                </div>
              </div>
              {error.isLive && <InputError>{error.isLive}</InputError>}

              <div className="low-volume mt-1">
                <input
                  type="checkbox"
                  name="allow"
                  checked={allowDispute}
                  className="checkbox"
                  onChange={() =>
                    setAllowDispute((v) =>
                      v
                        ? !v
                        : Object.entries(checkedState).filter(
                            ([key, value]) => key != 'isAllowDispute' && value
                          ).length > 0
                    )
                  }
                />
                <label className="label-bold">Allow Origin To Dispute</label>
              </div>
              <div>
                <label className="label-bold">Basis for Traceback</label>
                <ul className="list-unstyled">
                  {campaignAttributes.map(({ name, toolMessage, tag }, index) => {
                    return (
                      <li key={index}>
                        <div className="d-flex flex-row">
                          <div>
                            <input
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              name={'attributes'}
                              defaultChecked={checkedState[tag as keyof CampaignAttributes]}
                              onChange={(e) => handleAttributes(e, tag)}
                            />
                          </div>
                          <div className="ms-2">
                            <CustomToolTip tooltipVisible message={toolMessage}>
                              <label className="text-dark">{name}</label>
                            </CustomToolTip>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Fragment>
          )}
        </FormGroup>

        {!isView && (
          <Fragment>
            <label className="label-bold">Attachments</label>
          </Fragment>
        )}
        <div>
          {!isView && (
            <Fragment>
              <DropZone attachments={attachmentsArray} removeFile={removeFile} addFile={addFile} />
            </Fragment>
          )}
        </div>
        {!isView && (
          <div className="d-flex flex-row justify-content-center mt-2">
            <Button className="btn-default telecom-btn" onClick={handleCancelClick} color="light">
              Cancel
            </Button>
            <Button
              className="telecom-btn ms-4"
              onClick={(e) => {
                submitForm(e, !isAdd);
              }}
            >
              Save
            </Button>
          </div>
        )}
      </Form>
      <Modal
        centered
        isOpen={campaignLiveModal}
        className="submit-confirm-modal"
        toggle={toggleCampaignLiveModal}
      >
        <ModalHeader toggle={toggleCampaignLiveModal} />
        <ModalBody>
          Changing a campaign from prerecorded to live or live to prerecorded, may impact many
          tracebacks visible in the portal and reports. Are you sure you want to continue?
        </ModalBody>
        <ModalFooter className="m-auto">
          <div className="d-flex flex-row">
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={toggleCampaignLiveModal}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn red ms-2"
              onClick={(e) => {
                submitForm(e, false);
              }}
            >
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  const sources = sm.campaign.sources.map((source: CampaignSource) => {
    return {
      value: source.sourceId,
      label: source.name
    };
  });
  return {
    campaign: sm.campaign.campaign,
    labels: sm.campaign.labels,
    sources,
    user: sm.user
  };
};

const mapActionsToProps = {
  getCampaignObject,
  addSourceObject,
  addLabelObject,
  setCampaign
};

export default connect(mapStateToProps, mapActionsToProps)(CampaignEditor);
