import React, { Fragment, useState } from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { CallSource } from '../../../interfaces/callSource';
import { ValidationRules, regexRule, requiredRule } from '../../../lib/hooks';
import { validEmailRegex, validateIpAddress } from '../../../lib/regex';
import { SelectOption } from '../../CustomSelect';
import CountryDropdown from '../../HopDetail/CountryDropdown';
import InputError from '../../inputError';
import InputFormGroup from '../../inputFormGroup';

interface IProps {
  callDestination: CallSource;
  setCallDestination: React.Dispatch<React.SetStateAction<CallSource>>;
  errors: { [key: string]: string };
}

const customerTypes: SelectOption[] = [
  {
    value: 1,
    label: 'Trial account'
  },
  {
    value: 2,
    label: 'Customer less than 3 months'
  },
  {
    value: 3,
    label: 'Customer 3 - 12 months'
  },
  {
    value: 4,
    label: 'Customer more than 1 year'
  }
];

const actionsTaken: SelectOption[] = [
  {
    value: 1,
    label: 'No action taken'
  },
  {
    value: 2,
    label: 'Investigation'
  },
  {
    value: 3,
    label: 'Asked calling party for information'
  },
  {
    value: 4,
    label: 'Warned calling party'
  },
  {
    value: 5,
    label: 'Terminated calling party service'
  }
];

const averageDailyTraffic: SelectOption[] = [
  {
    value: 1,
    label: 'Less than 10.000'
  },
  {
    value: 2,
    label: '10.001 - 100.000'
  },
  {
    value: 3,
    label: '100.001 - 500.000'
  },
  {
    value: 4,
    label: 'More than 500.000'
  }
];

export const customerValidator: ValidationRules = {
  businessName: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  contactEmail: [
    {
      rule: regexRule(validEmailRegex),
      message: 'An email address is required.'
    }
  ],
  country: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  actionTaken: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  ipAddress: [
    {
      rule: validateIpAddress,
      message: "That's not a valid IP address."
    }
  ]
};

const CallDestinationCustomerStep: React.FC<IProps> = ({
  callDestination,
  setCallDestination,
  errors
}) => {
  const [customerType, setCustomerType] = useState<SelectOption | null>(null);
  const [actionTaken, setActionTaken] = useState<SelectOption | null>(null);
  const [averageTraffic, setAverageTraffic] = useState<SelectOption | null>(null);

  const handleCallDestinationInputChange = (e: any) => {
    if (e !== null && e.currentTarget) {
      const { name, value } = e.currentTarget;

      switch (name) {
        case 'businessName': {
          setCallDestination((v) => ({ ...v, businessName: value }));
          break;
        }
        case 'addressLine1': {
          setCallDestination((v) => ({ ...v, addressLine1: value }));
          break;
        }
        case 'contactName': {
          setCallDestination((v) => ({ ...v, contactName: value }));
          break;
        }
        case 'country': {
          setCallDestination((v) => ({ ...v, country: value }));
          break;
        }
        case 'city': {
          setCallDestination((v) => ({ ...v, city: value }));
          break;
        }
        case 'state': {
          setCallDestination((v) => ({ ...v, region: value }));
          break;
        }
        case 'contactPhone': {
          setCallDestination((v) => ({ ...v, contactPhone: value }));
          break;
        }
        case 'contactEmail': {
          setCallDestination((v) => ({ ...v, contactEmail: value }));
          break;
        }
        case 'ipAddress': {
          setCallDestination((v) => ({ ...v, ipAddress: value }));
          break;
        }
        case 'postalCode': {
          setCallDestination((v) => ({ ...v, postalCode: value }));
          break;
        }
        case 'stepsTaken': {
          setCallDestination((v) => ({ ...v, stepsTaken: value }));
          break;
        }
        default:
          break;
      }
    } else if (e !== null && e.label) {
      if (e.label === e.value) {
        setCallDestination((v) => ({ ...v, country: e.label }));
      } else setCallDestination((v) => ({ ...v, region: e.label }));
    } else {
      setCallDestination((v) => ({ ...v, country: '', region: '' }));
    }
  };
  const setCallDestinationFunction = (label: String) => {
    setCallDestination((v: any) => ({ ...v, country: label }));
  };
  return (
    <Fragment>
      <Row className="me-5 ms-5 mt-3">
        <Col lg="4">
          <Label className="telecom-label">
            Calling Party Business Name
            <i className="fa fa-asterisk asterisk" />
          </Label>
          <InputFormGroup
            isReadonly={false}
            inputName="businessName"
            inputId="businessName"
            inputClassName="input-hop"
            inputValue={callDestination.businessName}
            inputPlaceholder=""
            inputOnChange={handleCallDestinationInputChange}
            inputAutoComplete="off"
            valid={!!callDestination.businessName}
            errorMessage={errors.businessName}
          />
        </Col>
        <Col lg="4">
          <Label className="telecom-label">
            Calling Party Country
            <i className="fa fa-asterisk asterisk" />
          </Label>
          <CountryDropdown
            className={'country-dropdown'}
            name={'hopdetail-country-dropdown'}
            value={callDestination.country}
            setCountry={setCallDestinationFunction}
            extraOption
          />
          {errors.country && (
            <InputError className="telecom-input-error">{errors.country}</InputError>
          )}
        </Col>
        <Col lg="4">
          <Label className="telecom-label">Address</Label>
          <InputFormGroup
            isReadonly={false}
            inputName="addressLine1"
            inputId="addressLine1"
            inputClassName="input-hop"
            inputValue={callDestination.addressLine1}
            inputPlaceholder=""
            inputOnChange={handleCallDestinationInputChange}
            inputAutoComplete="off"
          />
        </Col>
      </Row>
      <div>
        <Row className="me-5 ms-5">
          <Col lg="4">
            <Label className="telecom-label">
              Contact Email
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <InputFormGroup
              invalid={!!errors.contactEmail && callDestination.contactEmail !== ''}
              valid={!errors.contactEmail && callDestination.contactEmail !== ''}
              isReadonly={false}
              inputName="contactEmail"
              inputId="contactEmail"
              inputClassName="input-hop"
              inputValue={callDestination.contactEmail}
              inputPlaceholder=""
              inputOnChange={handleCallDestinationInputChange}
              inputAutoComplete="off"
              errorMessage={errors.contactEmail}
            />
          </Col>
          <Col lg="4" className={errors.ipAddress ? '' : 'ip-form-group'}>
            <Label className="telecom-label">
              Calling Party IP Address
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <InputFormGroup
              invalid={
                !validateIpAddress(callDestination.ipAddress) && callDestination.ipAddress !== ''
              }
              valid={
                validateIpAddress(callDestination.ipAddress) || callDestination.ipAddress !== ''
              }
              isReadonly={false}
              inputName="ipAddress"
              inputId="ipAddress"
              inputClassName="input-hop"
              inputValue={callDestination.ipAddress}
              inputPlaceholder="127.0.0.1"
              inputOnChange={handleCallDestinationInputChange}
              inputAutoComplete="off"
              errorMessage={errors.ipAddress}
              inputErrorClassName="telecom-input-error"
            />
            {!errors.ipAddress && (
              <div className="text-end fst-italic fw-lighter text-muted small mb-3 pb-3">
                The use of IP 0.0.0.0 is allowed.
              </div>
            )}
          </Col>
          <Col lg="4">
            <Label className="telecom-label">City</Label>
            <InputFormGroup
              isReadonly={false}
              inputName="city"
              inputId="city"
              inputClassName="input-hop"
              inputValue={callDestination.city}
              inputPlaceholder=""
              inputOnChange={handleCallDestinationInputChange}
              inputAutoComplete="off"
            />
          </Col>
        </Row>
        <Row className="me-5 ms-5">
          <Col lg="4">
            <Label className="telecom-label">Contact Phone</Label>
            <InputFormGroup
              isReadonly={false}
              inputName="contactPhone"
              inputId="contactPhone"
              inputClassName="input-hop"
              inputValue={callDestination.contactPhone}
              inputPlaceholder=""
              inputOnChange={handleCallDestinationInputChange}
              inputAutoComplete="off"
            />
          </Col>
          <Col lg="4">
            <Label className="telecom-label">Calling Party Customer Type</Label>
            <Select
              className="customselect-large"
              classNamePrefix="customselect"
              options={customerTypes}
              value={customerType}
              onChange={(e: any) => {
                setCallDestination((v) => ({ ...v, customerType: e.label }));
                setCustomerType(e);
              }}
            />
          </Col>
          <Col lg="4">
            <Label className="telecom-label">State / Region</Label>
            <RegionDropdown
              country={callDestination.country}
              value={callDestination.region}
              className={'region-dropdown'}
              name={'hopdetail-region-dropdown'}
              onChange={(e: any) => {
                setCallDestination((v) => ({ ...v, region: e }));
              }}
            />
          </Col>
        </Row>
        <Row className="me-5 ms-5">
          <Col lg="4">
            <Label className="telecom-label">Contact Name</Label>
            <InputFormGroup
              isReadonly={false}
              inputName="contactName"
              inputId="contactName"
              inputClassName="input-hop"
              inputValue={callDestination.contactName}
              inputPlaceholder=""
              inputOnChange={handleCallDestinationInputChange}
              inputAutoComplete="off"
            />
          </Col>
          <Col lg="4">
            <Label className="telecom-label">Calling Party Average Daily Traffic</Label>
            <Select
              className="customselect-large"
              classNamePrefix="customselect"
              value={averageTraffic}
              options={averageDailyTraffic}
              onChange={(e: any) => {
                setCallDestination((v) => ({ ...v, averageTraffic: e.label }));
                setAverageTraffic(e);
              }}
            />
          </Col>
          <Col lg="4">
            <Label className="telecom-label">Zip / Postal Code</Label>
            <InputFormGroup
              isReadonly={false}
              inputName="postalCode"
              inputId="postalCode"
              inputClassName="input-hop"
              inputValue={callDestination.postalCode}
              inputPlaceholder=""
              inputOnChange={handleCallDestinationInputChange}
              inputAutoComplete="off"
            />
          </Col>
        </Row>
        <Row className="me-5 ms-5">
          <Col lg="4">
            <Label className="telecom-label">
              Action Taken
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <Select
              className="customselect-large"
              classNamePrefix="customselect"
              options={actionsTaken}
              value={actionTaken}
              onChange={(e: any) => {
                setCallDestination((v) => ({ ...v, actionTaken: e.label }));
                setActionTaken(e);
              }}
            />
            {errors.actionTaken && (
              <InputError className="telecom-input-error">{errors.actionTaken}</InputError>
            )}
          </Col>
          <Col lg="8">
            <Label className="telecom-label">Steps Taken</Label>
            <InputFormGroup
              isReadonly={false}
              isTextarea
              inputName="stepsTaken"
              inputId="stepsTaken"
              inputClassName="input-hop"
              inputValue={callDestination.stepsTaken}
              inputOnChange={handleCallDestinationInputChange}
              inputPlaceholder="Please describe the steps you have taken with your customer / end user / subscriber. Please note that blocking the Calling Number / ANI is insufficient. If not providing all customer information please explain."
              inputAutoComplete="off"
            />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
export default CallDestinationCustomerStep;
