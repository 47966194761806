import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import AdditionalCallDetailsSection from '../components/HopDetail/AdditionalCallDetailsSection';
import CallDetailsRow from '../components/HopDetail/CallDetailsRow';
import CallSourceSection from '../components/HopDetail/CallSourceSection';
import HopBreadcrumb from '../components/HopDetail/HopBreadcrumb';
import HopCardHeader from '../components/HopDetail/HopCardHeader';
import HopDetailsRow from '../components/HopDetail/HopDetailsRow';
import HopNavigationRow from '../components/HopDetail/HopNavigationRow';
import NRActionTaken from '../components/HopDetail/NRActionTaken';
import TracebackDetailsShortRow from '../components/HopDetail/TracebackDetailsShortRow';
import StirShakenRow from '../components/StirShakenRow';
import HopCommentsSection from '../components/comments/HopCommentsSection';
import Legend from '../components/shared/Legend';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { userTypes } from '../enum/userTypes';
import { EqaulHops, Hop } from '../interfaces/hop';
import { Traceback } from '../interfaces/traceback';
import { PersonalInfo } from '../interfaces/user';
import { usePrevious } from '../lib/hooks';
import { deleteHop, getHopObject, resetHopObject, updateHopObject } from '../redux/hop/thunks';
import { renotifyProvider } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import { getTracebackObject } from '../redux/traceback/thunks';

interface IProps {
  getHopObject: Function;
  getTracebackObject: Function;
  updateHopObject: Function;
  deleteHop: Function;
  resetHopObject: Function;
  renotifyProvider: Function;
  hop: Hop;
  statuses: { [key: number]: string };
  traceback: Traceback;
  error: string;
  user: PersonalInfo;
}

const HopDetail: React.FC<IProps> = ({
  hop,
  traceback,
  user,
  deleteHop,
  resetHopObject,
  renotifyProvider,
  updateHopObject,
  getHopObject,
  getTracebackObject,
  statuses,
  error
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isCallSourcePopulated, setIsCallSourcePopulated] = useState(false);
  const [callSourceClassName, setCallSourceClassName] = useState('');
  const callSourceRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    getHopObject(id);
  }, []);

  useEffect(() => {
    if (error === '404') {
      navigate('/hops');
    } else if (error && error !== '404') {
      navigate('/404');
    }
  }, [error]);

  const prevHop = usePrevious(hop);

  useEffect(() => {
    if (prevHop && hop.hopId === prevHop.hopId && !EqaulHops(prevHop, hop)) navigate('/hops');
  }, [hop]);

  useEffect(() => {
    if (hop.tracebackId) getTracebackObject(hop.tracebackId);
  }, [hop.tracebackId, hop.upstreamHopId]);

  useEffect(() => {
    if (hop && hop.forwardedCall && hop.forwardedCall.trim() !== '') {
      traceback.calledTN = hop.forwardedCall;
    }
  }, [hop.forwardedCall]);

  const goToCallSource = (e: any) => {
    e.preventDefault();
    callSourceRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    });
    setCallSourceClassName('border border-warning border-5');
    setTimeout(() => {
      setCallSourceClassName('');
    }, 10000);
  };
  return typeof hop === undefined || Object.keys(hop).length < 1 ? null : (
    <Fragment>
      <HopBreadcrumb
        hop={hop}
        traceback={traceback}
        user={user}
        resetHopObject={resetHopObject}
        deleteHop={deleteHop}
      />
      <Card className="telecom-card">
        <HopCardHeader hop={hop} user={user} renotifyProvider={renotifyProvider} />
        <CardBody className="hop-detail-body">
          {hop.status === 1 &&
            [userTypes.ProviderManager, userTypes.Provider].some((v) => v === user.roleType) && (
              <div className="p-3">
                <div className="card text-white bg-warning mb-3 ">
                  <div className="card-body">
                    <h5 className="card-title">Hop Response not complete:</h5>
                    <p className="card-text">
                      Please fill out the information below identifying either your upstream
                      Provider that you received the call from, or the end-user customer of yours
                      that placed the call, OR explain why you can not provide that information.{' '}
                      <a
                        href="#"
                        onClick={goToCallSource}
                        className="alert-link text-decoration-underline"
                      >
                        Respond Below.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
          <HopNavigationRow hop={hop} traceback={traceback} user={user} />
          <HopDetailsRow
            hop={hop}
            traceback={traceback}
            user={user}
            statuses={statuses}
            updateHopObject={updateHopObject}
          />
          <NRActionTaken hop={hop} traceback={traceback} />
          <TracebackDetailsShortRow hop={hop} traceback={traceback} user={user} />
          <CallDetailsRow hop={hop} traceback={traceback} />
          <Fragment>
            <h5 className="p-0 traceback-sub-title mb-3">Stir/Shaken</h5>
            <StirShakenRow stirShaken={hop.stirShaken} traceback={traceback} hop={hop} />
          </Fragment>

          <AdditionalCallDetailsSection hop={hop} traceback={traceback} user={user} />

          {(hop.status === 1 || hop.customer || hop.nfNr) && (
            <div ref={callSourceRef}>
              <CallSourceSection
                hop={hop}
                traceback={traceback}
                user={user}
                updateHopObject={updateHopObject}
                isCallSourcePopulated={isCallSourcePopulated}
                setIsCallSourcePopulated={setIsCallSourcePopulated}
                callSourceClassName={callSourceClassName}
              />
            </div>
          )}

          <HopCommentsSection hop={hop} isCallSourcePopulated={isCallSourcePopulated} user={user} />
        </CardBody>
      </Card>
      <div className="d-flex justify-content-center pt-4 pb-4">
        <Legend />
      </div>

      <ZendeskWebWidget />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    hop: sm.hop.hop,
    statuses: sm.hop.statuses,
    error: sm.hop.error,
    traceback: sm.traceback.traceback,
    user: sm.user
  };
};

const mapActionsToProps = {
  getHopObject,
  getTracebackObject,
  updateHopObject,
  deleteHop,
  resetHopObject,
  renotifyProvider
};

export default connect(mapStateToProps, mapActionsToProps)(HopDetail);
