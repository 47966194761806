export enum Auth {
  SIGNIN_USER = 'LOGIN_USER',
  SIGNOUT_USER = 'LOGOUT_USER',
  GET_USER = 'GET_USER',
  EDIT_USER = 'EDIT_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  SET_AUTH_LOADING_STATUS = 'SET_AUTH_LOADING_STATUS',
  SET_AUTH_ERROR_MESSAGE = 'SET_AUTH_ERROR_MESSAGE',
  REMEMBER_LAST_PAGE = 'REMEMBER_LAST_PAGE',
  REMEMBER_LAST_USER_ID = 'REMEMBER_LAST_USER_ID',
  SET_CHANGE_PASSWORD_ERROR_MESSAGE = 'SET_CHANGE_PASSWORD_ERROR_MESSAGE'
}
