import React, { FC, useState } from 'react';
import { Hop } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import BasisForTraceback from './BasisForTraceback';
import TerminatingProviderDetailsRow from './TerminatingProviderDetailsRow';
import TracebackDetailsRow from './TracebackDetailsRow';

interface IProps {
  hop: Hop;
  traceback: Traceback;
  user: PersonalInfo;
}

const AdditionalCallDetailsSection: FC<IProps> = ({ hop, traceback, user }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };
  return (
    <div className="additionalCallDetails">
      <h5 className="p-0 pb-3" onClick={toggleVisibility} role="button">
        <i
          className={`${visible ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'} pe-2 custom-blue`}
        ></i>
        Additional Call Details
      </h5>
      <div
        className={`mb-3 me-0 ${visible ? 'd-flex' : 'd-none'} flex-column highlighted-background`}
      >
        <span className="table-cell-gray">{hop.description || 'None'}</span>
        <TerminatingProviderDetailsRow traceback={traceback} />
        <TracebackDetailsRow hop={hop} traceback={traceback} user={user} />
        <BasisForTraceback source={traceback.campaignAttributes} user={user} />
      </div>
    </div>
  );
};

export default AdditionalCallDetailsSection;
