import React, { Fragment } from 'react';
import { Col, Container } from 'reactstrap';
import { DateFormat } from '../enum/DateFormat';
import { StirShakenReponse } from '../interfaces/stirShaken';
import { getClientFormattedDate, getStirShakenExplanation } from '../lib/utilities';

interface IProps {
  stirShaken: StirShakenReponse;
}

const StirShakenIncidentRow: React.FC<IProps> = ({ stirShaken }) => {
  return (
    <Fragment>
      <h5 className="p-0 traceback-sub-title mb-3 mt-3">Stir/Shaken</h5>
      {stirShaken ? (
        <Container>
          {!stirShaken.signer ? (
            <div className="d-flex flex-row">
              <div className="d-flex pe-3">
                <span className="font-calibri-bold pe-2">Attestation</span>
                {stirShaken.attest}
              </div>
              <div className="d-flex pe-3">
                <span className="font-calibri-bold pe-2">IAT</span>
                <span className="table-cell-gray">
                  {' '}
                  {getClientFormattedDate(stirShaken.iat, DateFormat.LongBoth)}
                </span>
              </div>
              <div className="d-flex pe-3">
                <span className="font-calibri-bold pe-2">Orig #</span>
                <span className="table-cell-gray">{stirShaken.orig}</span>
              </div>
              <div className="d-flex pe-3">
                <span className="font-calibri-bold pe-2">Data Input</span>
                <span className="table-cell-gray">
                  {getStirShakenExplanation(stirShaken.status)}
                </span>
              </div>
              {stirShaken.isCallSigner && (
                <div className="d-flex pe-3">
                  <span className="font-calibri-bold pe-2">Call signer</span>
                  <span className="table-cell-gray">Yes</span>
                </div>
              )}
              <div className="d-flex pe-3">
                <span className="font-calibri-bold pe-2">Dest #</span>
                <span className="table-cell-gray d-flex flex-column">
                  {stirShaken.dest.tn && stirShaken.dest.tn.join(', ')}
                  {stirShaken.dest.tn &&
                    stirShaken.dest.uri &&
                    stirShaken.dest.tn.length > 0 &&
                    stirShaken.dest.uri.length > 0 &&
                    ', '}
                  {stirShaken.dest.uri && stirShaken.dest.uri.join(', ')}
                </span>
              </div>
              <div className="d-flex pe-3">
                <span className="font-calibri-bold pe-2">Signed Carrier</span>
                {stirShaken.origid}
              </div>
              <div className="d-flex pe-3">
                <span className="font-calibri-bold pe-2">Date Added</span>
                {getClientFormattedDate(stirShaken.create_date, DateFormat.LongBoth)}
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row">
              <Col className="pb-3">
                <div className="d-flex pe-3">
                  <span className="font-calibri-bold pe-2">Attestation</span>
                  {stirShaken.attest}
                </div>
                <div className="d-flex pe-3">
                  <span className="font-calibri-bold pe-2">IAT</span>
                  <span className="table-cell-gray">
                    {' '}
                    {getClientFormattedDate(stirShaken.iat, DateFormat.LongBoth)}
                  </span>
                </div>
                <div className="d-flex pe-3">
                  <span className="font-calibri-bold pe-2">Orig #</span>
                  <span className="table-cell-gray">{stirShaken.orig}</span>
                </div>
                <div className="d-flex pe-3">
                  <span className="font-calibri-bold pe-2">Dest #</span>
                  <span className="table-cell-gray d-flex flex-column">
                    {stirShaken.dest.tn && stirShaken.dest.tn.join(', ')}
                    {stirShaken.dest.tn &&
                      stirShaken.dest.uri &&
                      stirShaken.dest.tn.length > 0 &&
                      stirShaken.dest.uri.length > 0 &&
                      ', '}
                    {stirShaken.dest.uri && stirShaken.dest.uri.join(', ')}
                  </span>
                </div>
                <div className="d-flex pe-3">
                  <span className="font-calibri-bold pe-2">Data Input</span>
                  <span className="table-cell-gray">
                    {getStirShakenExplanation(stirShaken.status)}
                  </span>
                </div>
              </Col>
              <Col className="pb-3">
                <div>
                  <span className="font-calibri-bold pe-2">Signer Org</span>
                  {stirShaken.signer.organization.join(', ')}
                </div>
                <div>
                  <span className="font-calibri-bold pe-2">Signer OrgUnit</span>
                  {stirShaken.signer.organizationUnit.join(', ')}
                </div>
                <div>
                  <span className="font-calibri-bold pe-2">Signer Common Name</span>
                  {stirShaken.signer.commonName}
                </div>
                <div>
                  <span className="font-calibri-bold pe-2">Signed Carrier</span>
                  {stirShaken.signer &&
                  stirShaken.signer.organization &&
                  stirShaken.signer.organization.length > 0
                    ? stirShaken.signer.organization.join(', ')
                    : stirShaken.origid}
                </div>
                {stirShaken.isCallSigner && (
                  <div>
                    <span className="font-calibri-bold pe-2">Call signer</span>
                    <span className="table-cell-gray">Yes</span>
                  </div>
                )}
              </Col>
              <Col className="pb-3">
                <div>
                  <span className="font-calibri-bold pe-2">Issuer Org</span>
                  {stirShaken.issuer?.organization.join(', ')}
                </div>
                <div>
                  <span className="font-calibri-bold pe-2">Issuer OrgUnit</span>
                  {stirShaken.issuer?.organizationUnit.join(', ')}
                </div>
                <div>
                  <span className="font-calibri-bold pe-2">Issuer Common Name</span>
                  {stirShaken.issuer?.commonName}
                </div>
                {stirShaken.signerProviderName && (
                  <div>
                    <span className="font-calibri-bold pe-2">Signer</span>
                    <a href={`providers/provider/${stirShaken.signerProviderId}`}>
                      {stirShaken.signerProviderName}
                    </a>
                  </div>
                )}
                <div>
                  <span className="font-calibri-bold pe-2">Date Added</span>
                  {getClientFormattedDate(stirShaken.create_date, DateFormat.LongBoth)}
                </div>
              </Col>
            </div>
          )}
        </Container>
      ) : (
        <span style={{ marginLeft: '25px' }}>No Stir/Shaken data available</span>
      )}
      {stirShaken && stirShaken.errorMessage && (
        <div className="stirShakenError mb-3">{stirShaken.errorMessage}</div>
      )}
    </Fragment>
  );
};

export default StirShakenIncidentRow;
