import React, { FC, Fragment, useMemo } from 'react';
import { RMDEntry } from '../interfaces/rmd';

import { Row } from 'reactstrap';

interface IProps {
  rmds: RMDEntry[];
  setRmd: (rmd: RMDEntry) => void;
  addRMDToProvider?: boolean;
}

const mapRMDs = (rmds: RMDEntry[], setRmd: (rmd: RMDEntry) => void) =>
  rmds.map((item) => (
    <button
      key={item.number}
      type="button"
      className="btn btn-link"
      onClick={() => {
        setRmd(item);
      }}
    >
      <div className="rmd-providerName">
        {item.businessName} - {item.country} - {item.number.replace('RMD', 'RMD ')}
        {item.providerName && (
          <span
            className={item.providerActive ? 'text-green' : 'text-red'}
          >{` - ${item.providerName}`}</span>
        )}
      </div>
    </button>
  ));

const DisplayRmds: FC<IProps> = ({ rmds, setRmd, addRMDToProvider }) => {
  const rmdsWithoutProviders = useMemo(() => rmds.filter((rmd) => !rmd.providerName), [rmds]);
  const rmdsWithActiveProviders = useMemo(
    () => rmds.filter((rmd) => !!rmd.providerName && rmd.providerActive),
    [rmds]
  );
  const rmdsWithInactiveProviders = useMemo(
    () => rmds.filter((rmd) => !!rmd.providerName && !rmd.providerActive),
    [rmds]
  );
  return (
    <Fragment>
      {rmdsWithActiveProviders.length > 0 && (
        <Row
          className="m-0 flex-column align-items-start mb-3 pb-3 pt-3"
          style={{ backgroundColor: '#e6e6e6' }}
        >
          {addRMDToProvider ? (
            rmdsWithActiveProviders.map((item: RMDEntry) => (
              <div className="duplicate-rmd p-3" key={item.id}>
                <h6 className="text-navy">
                  This RMD number is already associated with{' '}
                  <span className="text-green">{`[${item.providerName} - ${item.number}]`}</span>.
                  <br /> You must remove the number from them before adding to the current provider.
                </h6>
              </div>
            ))
          ) : (
            <Fragment>
              <h5 className="text-navy ps-2">
                <u>Provider(s) already exist, click to select:</u>
              </h5>
              {mapRMDs(rmdsWithActiveProviders, setRmd)}
            </Fragment>
          )}
        </Row>
      )}
      {rmdsWithoutProviders.length > 0 && (
        <Row className={`m-0 flex-column align-items-start`}>
          <h5 className="text-navy ps-2">
            <u>{addRMDToProvider ? 'Add RMD to provider ' : 'Create new provider from RMD '}</u>
          </h5>
          {mapRMDs(rmdsWithoutProviders, setRmd)}
        </Row>
      )}
      {rmdsWithInactiveProviders.length > 0 && (
        <Row
          className="m-0 flex-column align-items-start my-3 py-3 pt-3"
          style={{ backgroundColor: '#e6e6e6' }}
        >
          {addRMDToProvider ? (
            rmdsWithInactiveProviders.map((item: RMDEntry) => (
              <div className="duplicate-rmd p-3" key={item.id}>
                <h6 className="text-navy">
                  This RMD number is already associated with{' '}
                  <span
                    className={item.providerActive ? 'text-green' : 'text-red'}
                  >{`[${item.providerName} - ${item.number}]`}</span>
                  .
                  <br /> You must remove the number from them before adding to the current provider.
                </h6>
              </div>
            ))
          ) : (
            <Fragment>
              <h5 className="text-navy ps-2">
                <u>
                  Inactive Provider(s), please contact support@tracebacks.org if you believe this to
                  be in error:
                </u>
              </h5>
              {mapRMDs(rmdsWithInactiveProviders, () => {})}
            </Fragment>
          )}
        </Row>
      )}
    </Fragment>
  );
};

export default DisplayRmds;
