import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import React, { useEffect, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { userTypes } from '../../enum/userTypes';
import { ChartFilterElementsForProviderSummary } from '../../interfaces/charts';
import { PersonalInfo } from '../../interfaces/user';
import { dataProvider, setChartLegendPosition } from '../../lib/chart/data';
import { getProviderStatsObject } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController
);

const options: ChartOptions<'bar' | 'line'> = {
  plugins: {
    title: {
      display: false,
      text: 'Provider Stats Chart'
    },
    legend: {}
  },
  responsive: true,
  scales: {
    x: { stacked: true },
    y: { stacked: true }
  }
};

interface IProps {
  user: PersonalInfo;
  providerStats: Object;
  getProviderStatsObject: Function;
  providerId: number;
  filterElements: ChartFilterElementsForProviderSummary;
  height?: number;
  width?: number;
  legendPosition: string;
}

const ProviderStatsChart: React.FC<IProps> = ({
  user,
  providerStats,
  getProviderStatsObject,
  providerId,
  filterElements,
  height,
  legendPosition
}) => {
  const dataChart = dataProvider(providerStats, user.roleType === userTypes.Admin);

  const displayChart = useMemo(
    () =>
      dataChart &&
      dataChart.datasets.filter((v) => v.type === 'bar' && v.data.some((v) => v > 0)).length !== 0,
    [dataChart]
  );

  useEffect(() => {
    getProviderStatsObject(filterElements, providerId);
  }, [
    providerId,
    filterElements.labelId,
    filterElements.note,
    filterElements.termState,
    filterElements.startDate,
    filterElements.endDate
  ]);

  return dataChart && displayChart ? (
    <Chart
      type="bar"
      className="d-inline-flex"
      options={setChartLegendPosition(legendPosition, options)}
      data={dataChart}
      height={height}
    />
  ) : (
    <h5 className="pt-5">No Data Available</h5>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    providerStats: sm.provider.providerStats
  };
};

const mapActionsToProps = {
  getProviderStatsObject
};

export default connect(mapStateToProps, mapActionsToProps)(ProviderStatsChart);
