import { Pagination } from '../../interfaces/pagination';
import { ProviderFollowingRequest } from '../../interfaces/provider';
import { downloadFile } from '../../lib/utilities';
import {
  getAllCampaignFolowersAction,
  getAllCampaignFolowersSummariesAction,
  getAllFolowersAction,
  getAllFolowersSummariesAction,
  setHomePageErrorMessage,
  setHomePageLoadingStatus
} from './actions';
import {
  getAllCampaignFollowersApiCall,
  getAllCampaignFollowersSummariesApiCall,
  getAllFollowersApiCall,
  getAllFollowersSummariesApiCall,
  getFollowingProvidersApiCall
} from './apiCalls';

export const getGovFollowers = () => async (dispatch: any) => {
  dispatch(setHomePageLoadingStatus(true));
  try {
    const response = await getAllFollowersApiCall();
    if (response && response.data.data) {
      dispatch(getAllFolowersAction(response.data.data));
      dispatch(setHomePageLoadingStatus(false));
      return response.data.data;
    }
  } catch (error: any) {
    dispatch(setHomePageErrorMessage(error.message));
  }
};
export const getGovCampaignFollowers = () => async (dispatch: any) => {
  dispatch(setHomePageLoadingStatus(true));
  try {
    const response = await getAllCampaignFollowersApiCall();
    if (response && response.data.data) {
      dispatch(getAllCampaignFolowersAction(response.data.data));
      dispatch(setHomePageLoadingStatus(false));
      return response.data.data;
    }
  } catch (error: any) {
    dispatch(setHomePageErrorMessage(error.message));
  }
};

export const getGovFollowersSummaries =
  (request: ProviderFollowingRequest, params: Pagination) => async (dispatch: any) => {
    dispatch(setHomePageLoadingStatus(true));
    try {
      const response = await getAllFollowersSummariesApiCall(request, params);
      if (response && response.data.data) {
        dispatch(getAllFolowersSummariesAction(response.data.data));
        dispatch(setHomePageLoadingStatus(false));
        return response.data.data;
      }
    } catch (error: any) {
      dispatch(setHomePageErrorMessage(error.message));
    }
  };

export const getGovCampaignFollowersSummaries = (params: Pagination) => async (dispatch: any) => {
  dispatch(setHomePageLoadingStatus(true));
  try {
    const response = await getAllCampaignFollowersSummariesApiCall(params);
    if (response && response.data.data) {
      dispatch(getAllCampaignFolowersSummariesAction(response.data.data));
      dispatch(setHomePageLoadingStatus(false));
      return response.data.data;
    }
  } catch (error: any) {
    dispatch(setHomePageErrorMessage(error.message));
  }
};

export const getFollowingProvidersCSV =
  (request: ProviderFollowingRequest, params: Pagination) => async (dispatch: any) => {
    dispatch(setHomePageLoadingStatus(true));
    try {
      const response = await getFollowingProvidersApiCall(request, params);
      downloadFile(response.data, response.headers.contentfilename);
      dispatch(setHomePageLoadingStatus(false));
    } catch (error: any) {
      dispatch(setHomePageErrorMessage(error.message));
    }
  };
