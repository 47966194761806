import { sub } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, Card, CardBody, CardHeader, CardTitle, Collapse, Label } from 'reactstrap';
import { SelectOption } from '../components/CustomSelect';
import HomePageProviderTable from '../components/HomePageProviderTable';
import ProvidersSelect from '../components/ProvidersSelect';
import { DateFormat } from '../enum/DateFormat';
import { Pagination } from '../interfaces/pagination';
import { ProviderInfoType } from '../lib/chart/data';
import { getEndOfDayAsString, getStartOfDayAsString } from '../lib/utilities';
import { getFollowingProvidersCSV, getGovFollowers } from '../redux/govFollower/thunk';
import { stateMappings } from '../redux/stateMappings';
import { Provider } from '../interfaces/provider';
import CustomPicky from '../components/CustomPicky';

interface IProps {
  getFollowingProvidersCSV: Function;
  getGovFollowers: Function;
  govFollowers: Provider[];
}

interface DParams {
  request: any;
  params: Pagination;
}
const providerInfoMapping = {
  [ProviderInfoType.NewProvider]: {
    title: 'Providers Added to Portal',
    providerLabel: 'First Identified By'
  },
  [ProviderInfoType.NrProvider]: {
    title: 'Non-Responsive Providers',
    providerLabel: 'NR Provider or their Immediate Downstream'
  },
  [ProviderInfoType.NfProvider]: {
    title: 'Not-Found Providers',
    providerLabel: 'NF Provider or their Immediate Downstream'
  },
  [ProviderInfoType.FollowingProviders]: {
    title: 'Following Providers',
    providerLabel: 'Following Providers'
  }
};

const ProvidersInfo: React.FC<IProps> = ({
  getFollowingProvidersCSV,
  getGovFollowers,
  govFollowers
}) => {
  const { pathname } = useLocation();

  const providerInfoType = useMemo(() => pathname as ProviderInfoType, [pathname]);
  const [dParams, setDParams] = useState<DParams | null>(null);

  const mapping = useMemo(() => providerInfoMapping[providerInfoType], [providerInfoType]);

  const [startDate, setStartDate] = useState<string | undefined | Date>(
    getStartOfDayAsString(sub(new Date(), { days: 90 }), DateFormat.DBDate)
  );
  const [endDate, setEndDate] = useState<string | undefined | Date>(
    getEndOfDayAsString(new Date(), DateFormat.DBDate)
  );
  const [collapseFilters, setCollapseFilters] = useState(false);
  const [chosenProvider, setChosenProvider] = useState(0);
  const [chosenProviders, setChosenProviders] = useState([]);
  const followingProviderOptions = useMemo(
    () =>
      govFollowers &&
      govFollowers.map(
        (provider: Provider): SelectOption => ({
          value: provider.providerId,
          label: provider.name + ' (' + provider.country + ')'
        })
      ),
    [govFollowers]
  );

  const toggleFilters = () => {
    setCollapseFilters((v) => !v);
  };

  const handleChange = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setStartDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
          );
        } else setStartDate(e);
        break;
      case 'endDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setEndDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
          );
        } else setEndDate(e);
        break;
      case 'chosenProvider':
        if (e) setChosenProvider(e.value);
        else setChosenProvider(0);
        break;
      case 'chosenProviders':
        setChosenProviders(e ? e.map((item: SelectOption) => item.value) : []);
        break;
    }
  };

  useEffect(() => {
    if (providerInfoType === ProviderInfoType.FollowingProviders && !govFollowers)
      getGovFollowers();
  }, []);

  return (
    <Card className="table-card mt-5 mb-5">
      <CardHeader className="card-header-provider">
        <CardTitle tag="h4">{mapping.title}</CardTitle>
        <CardHeader
          className="card-header-filters"
          onClick={() => toggleFilters()}
          data-type="collapseBanner"
        >
          Filters
          <i
            className={`filters fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
          ></i>
        </CardHeader>
        <Collapse isOpen={collapseFilters}>
          <CardBody>
            <div className="row">
              <div className="fixed-column ps-0">
                <Label className="telecom-label">Start Date</Label>
              </div>
              <div className="fixed-column ps-0">
                <Label className="telecom-label">End Date</Label>
              </div>
              <div className="fixed-column ps-0">
                <Label className="telecom-label">{mapping.providerLabel}</Label>
              </div>
            </div>
            <div className="row">
              <div className="fixed-column">
                <DatePicker
                  maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
                  selected={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'startDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                />
              </div>
              <div className="fixed-column">
                <DatePicker
                  maxDate={new Date()}
                  selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
                  minDate={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'endDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                />
              </div>
              <div className="fixed-column">
                {providerInfoType === ProviderInfoType.FollowingProviders ? (
                  <CustomPicky
                    className="customselect-small"
                    selectedOption={chosenProviders}
                    typeLabel={'All Providers'}
                    isSearchable
                    setSelectedOption={(option) => handleChange(option, 'chosenProviders')}
                    getOptions={followingProviderOptions}
                    numberDisplayed={2}
                  />
                ) : (
                  <ProvidersSelect
                    onChange={(option) => handleChange(option, 'chosenProvider')}
                    isSearchable
                    isClearable
                    className={'customselect-small'}
                    option={chosenProvider}
                    placeholder="All Providers"
                    includeInactive
                  />
                )}
              </div>
              {providerInfoType === ProviderInfoType.FollowingProviders && (
                <div className="col">
                  <div className="d-flex justify-content-xl-end">
                    <Button
                      onClick={() => {
                        if (dParams) getFollowingProvidersCSV(dParams.request, dParams.params);
                      }}
                      className="downloadCsvStyle"
                    >
                      <i className="fa fa-download" />
                      {` Download CSV`}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Collapse>
      </CardHeader>
      <CardBody>
        <HomePageProviderTable
          setDownloadParams={(request, pagination) =>
            setDParams({ params: pagination, request: request })
          }
          providerInfoType={providerInfoType}
          homeTable={false}
          pageSize={50}
          startDate={startDate}
          endDate={endDate}
          providerSelected={chosenProvider}
          providersSelected={chosenProviders}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { govFollowers: sm.govFollowers.govFollowers };
};
const mapActionsToProps = { getFollowingProvidersCSV, getGovFollowers };

export default connect(mapStateToProps, mapActionsToProps)(ProvidersInfo);
