import { Auth } from './actionEnum';

const auth = {
  expires_in: 1800,
  expiresDuration: '',
  id: 0,
  name: ''
};

const user = {
  accessFailedCount: 0,
  companyName: '',
  email: '',
  emailConfirmed: false,
  id: 0,
  isActive: false,
  isEscalationEmail: false,
  isTBEmail: false,
  name: '',
  providerId: 0,
  roleType: 0,
  twoFactorEnabled: false,
  userName: ''
};

const defaultState: any = {
  auth: JSON.parse(localStorage.getItem('auth') || JSON.stringify(auth)),
  user: JSON.parse(localStorage.getItem('user') || JSON.stringify(user)),
  loading: 0,
  error: '',
  changePasswordError: '',
  lastPageUrl: '',
  lastUserId: 0
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Auth.SIGNIN_USER:
      return { ...state, auth: { ...state.auth, ...action.payload.data } };

    case Auth.GET_USER:
      return { ...state, user: action.payload.data, lastUserId: action.payload.data.id };

    case Auth.EDIT_USER:
      return { ...state, user: action.payload.data };

    case Auth.DEACTIVATE_USER:
      return { ...state };

    case Auth.SET_AUTH_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Auth.SET_AUTH_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    case Auth.SET_CHANGE_PASSWORD_ERROR_MESSAGE:
      return { ...state, changePasswordError: action.payload };

    case Auth.SIGNOUT_USER:
      return { ...state, error: '', loading: false, user: {}, auth: {} };
    case Auth.REMEMBER_LAST_PAGE:
      return { ...state, lastPageUrl: action.payload };
    case Auth.REMEMBER_LAST_USER_ID:
      return { ...state, lastUserId: action.payload };
    default:
      return state;
  }
};

export default reducer;
