import { sub } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { DateFormat } from '../enum/DateFormat';
import { Configuration } from '../interfaces/configuration';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import {
  NewProviderDetail,
  NrOrNfProviderDetail,
  ProviderSummaryRow
} from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import { ProviderInfoType } from '../lib/chart/data';
import { newProviderColumns } from '../lib/dataTableUtils/newProviderColumns';
import { nrOrNfProviderColumns } from '../lib/dataTableUtils/nrProviderColumns';
import { providerColumns } from '../lib/dataTableUtils/providerColumns';
import { getEndOfDayAsString, getStartOfDayAsString, toUTCDate } from '../lib/utilities';
import { getClientConfigurationInfo } from '../redux/appinfo/thunks';
import { getGovFollowersSummaries } from '../redux/govFollower/thunk';
import { getNewProvidersInfo, getNrOrProvidersInfo } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import CustomDataTable from './CustomDataTable';
import ProviderExpandableRow from './ProviderExpandableRow';

interface IProps {
  providerInfoType: ProviderInfoType;
  newProviders: NewProviderDetail[];
  nrProviders: NrOrNfProviderDetail[];
  nfProviders: NrOrNfProviderDetail[];
  govFollowersSummaries: ProviderSummaryRow[];
  getNewProvidersInfo: Function;
  getNrOrProvidersInfo: Function;
  getGovFollowersSummaries: Function;
  user: PersonalInfo;
  homeTable: boolean;
  countNewProviders: number;
  countNrProviders: number;
  countNfProviders: number;
  countGovFollowersSummaries: number;
  pageSize: number;
  startDate?: string | Date;
  endDate?: string | Date;
  providerSelected?: number;
  providersSelected?: number[];
  configuration: Configuration;
  getClientConfigurationInfo: Function;
  setDownloadParams?: (request: any, params: Pagination) => void;
}

const HomePageProviderTable: React.FC<IProps> = ({
  newProviders,
  nrProviders,
  getNewProvidersInfo,
  getNrOrProvidersInfo,
  providerInfoType,
  user,
  homeTable,
  countNewProviders,
  countNrProviders,
  pageSize,
  startDate,
  endDate,
  providerSelected,
  providersSelected,
  nfProviders,
  countNfProviders,
  govFollowersSummaries,
  getGovFollowersSummaries,
  countGovFollowersSummaries,
  configuration,
  getClientConfigurationInfo,
  setDownloadParams
}) => {
  const defaultStartDate = useMemo(
    () =>
      startDate ||
      getStartOfDayAsString(
        sub(new Date(), {
          days: 90
        }),
        DateFormat.DBDate
      ),
    [startDate]
  );
  const defaultEndDate = useMemo(
    () => endDate || getEndOfDayAsString(new Date(), DateFormat.DBDate),
    [endDate]
  );

  const providerMapping = useMemo(
    () =>
      ({
        [ProviderInfoType.NewProvider]: {
          tableData: newProviders,
          columns: newProviderColumns(user),
          noDataText: 'No provider Created in Last 90 days',
          paginationTotalRows: countNewProviders,
          paginationSort: 'dateAdded',
          update: (paginationParams: Pagination) => {
            getNewProvidersInfo(
              {
                startDate: defaultStartDate,
                endDate: defaultEndDate,
                searchedProvider: providerSelected
              },
              paginationParams
            );
          }
        },
        [ProviderInfoType.NrProvider]: {
          tableData: nrProviders,
          columns: nrOrNfProviderColumns(user, true),
          noDataText: 'No NR provider in Last 90 days',
          paginationTotalRows: countNrProviders,
          paginationSort: 'nrTracebacks',
          update: (paginationParams: Pagination) => {
            getNrOrProvidersInfo(
              {
                startDate: defaultStartDate,
                endDate: defaultEndDate,
                searchedProvider: providerSelected,
                isNr: true
              },
              paginationParams
            );
          }
        },
        [ProviderInfoType.NfProvider]: {
          tableData: nfProviders,
          columns: nrOrNfProviderColumns(user, false),
          noDataText: 'No NF provider in Last 90 days',
          paginationTotalRows: countNfProviders,
          paginationSort: 'nrTracebacks',
          update: (paginationParams: Pagination) => {
            getNrOrProvidersInfo(
              {
                startDate: defaultStartDate,
                endDate: defaultEndDate,
                searchedProvider: providerSelected,
                isNr: false
              },
              paginationParams
            );
          }
        },
        [ProviderInfoType.FollowingProviders]: {
          tableData: govFollowersSummaries,
          columns: providerColumns(user.roleType, true),
          noDataText: 'No Provider Followed',
          paginationTotalRows: countGovFollowersSummaries,
          paginationSort: 'ps.score',
          update: (paginationParams: Pagination) => {
            getGovFollowersSummaries(
              {
                startDate: toUTCDate(defaultStartDate?.toString() || ''),
                endDate: toUTCDate(defaultEndDate?.toString() || ''),
                chosenProviders: providersSelected || []
              },
              paginationParams
            );
          }
        }
      })[providerInfoType],
    [
      providerInfoType,
      user,
      defaultStartDate,
      defaultEndDate,
      providerSelected,
      providersSelected,
      nrProviders,
      nfProviders,
      newProviders,
      govFollowersSummaries
    ]
  );

  const [paginationParams, setPaginationParams] = useState({
    ...defaultPagination(),
    sort: providerMapping.paginationSort,
    order: 'desc',
    pageSize: pageSize
  });

  useEffect(() => {
    providerMapping.update(paginationParams);
    if (providerInfoType === ProviderInfoType.FollowingProviders && setDownloadParams)
      setDownloadParams(
        {
          startDate: toUTCDate(defaultStartDate?.toString() || ''),
          endDate: toUTCDate(defaultEndDate?.toString() || ''),
          chosenProviders: providersSelected || []
        },
        paginationParams
      );
  }, [
    defaultStartDate,
    defaultEndDate,
    providerSelected,
    providersSelected,
    paginationParams,
    providerInfoType
  ]);

  useEffect(() => {
    if (providerInfoType === ProviderInfoType.FollowingProviders) getClientConfigurationInfo();
  }, [providerInfoType]);

  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  return (
    <div className={`${!homeTable ? 'mb-5' : ''}`}>
      <CustomDataTable
        tableData={providerMapping.tableData}
        columns={providerMapping.columns}
        noDataText={providerMapping.noDataText}
        defaultSortFieldId={paginationParams.sort}
        defaultPage={paginationParams.page}
        defaultPageSize={paginationParams.pageSize}
        paginationTotalRows={providerMapping.paginationTotalRows}
        updatePaginationParams={updatePagination}
        pagination={!homeTable}
        maxPageSize={10}
        useExpandableRows={
          providerInfoType === ProviderInfoType.FollowingProviders &&
          configuration.reputationVisible
        }
        useExpandableRowsComponent={({ data }) => (
          <ProviderExpandableRow
            data={data}
            includeLinks
            startDate={toUTCDate(defaultStartDate?.toString() || '').toISOString()}
            endDate={toUTCDate(defaultEndDate?.toString() || '').toISOString()}
          />
        )}
      />
      {homeTable && (
        <div className="mt-3 ms-5">
          <a href={providerInfoType}>{`See More >>`}</a>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    newProviders: sm.provider.newProvidersList,
    countNewProviders: sm.provider.countNewProviders,
    nrProviders: sm.provider.nrProvidersList,
    nfProviders: sm.provider.nfProvidersList,
    govFollowersSummaries: sm.govFollowers.govFollowersSummaries,
    countNrProviders: sm.provider.countNrProviders,
    countNfProviders: sm.provider.countNfProviders,
    countGovFollowersSummaries: sm.govFollowers.countGovFollowersSummaries,
    user: sm.user,
    configuration: sm.appinfo.configuration
  };
};

const mapActionsToProps = {
  getNewProvidersInfo,
  getNrOrProvidersInfo,
  getGovFollowersSummaries,
  getClientConfigurationInfo
};

export default connect(mapStateToProps, mapActionsToProps)(HomePageProviderTable);
