import { userTypes } from '../enum/userTypes';
import { Requestor } from './request';

export interface SignInUser {
  username: string;
  password: string;
}
export interface EmailValidation {
  id: number;
  address: string;
  status: string;
  sub_status: string;
  aws_reason: null | string;
  domain: string;
  domain_age_days: null | string;
  processed_at: string;
}

export interface PersonalInfo {
  accessFailedCount: number;
  companyName: string;
  email: string;
  dnoAccess?: boolean;
  emailConfirmed: boolean;
  emailValidation?: EmailValidation;
  id: number;
  isActive: boolean;
  isEscalationEmail: boolean;
  isTBEmail: boolean;
  name: string;
  phoneNumber: number;
  providerId: number;
  roleType: userTypes | number;
  setupCompleted: boolean;
  twoFactorEnabled: boolean;
  userName: string;
  providerCountry?: string;
  insightAgreed?: boolean;
  isRequestor?: boolean;
  requestors?: Requestor[];
  allowAutomationAPIs: boolean;
  status: number;
  providerName?: string;
  nrHops?: number;
}

export interface UserInfo {
  id: number;
  name: string;
  email: string;
  phoneNumber: number;
  providerName: string;
}

export function emptyPersonalInfo(): PersonalInfo {
  return {
    accessFailedCount: 0,
    companyName: '',
    email: '',
    emailConfirmed: false,
    id: 0,
    isActive: false,
    isEscalationEmail: false,
    isTBEmail: false,
    name: '',
    phoneNumber: 0,
    providerId: 0,
    roleType: 0,
    setupCompleted: false,
    twoFactorEnabled: false,
    userName: '',
    insightAgreed: false,
    isRequestor: false,
    allowAutomationAPIs: false,
    status: 0,
    providerName: ''
  };
}

export interface ResetEmail {
  callbackUrl: string;
}

export interface CreatePasswordPostData {
  passcode: string;
  password: string;
}

export interface CreateAdminPostData {
  email: string;
  userName: string;
  providerId?: number;
  isActive: boolean;
  isEscalationEmail: boolean;
  isTBEmail: boolean;
  name: string;
  phoneNumber: number;
  roleType?: number;
  requestorIds?: any;
  allowAutomationAPIs: boolean;
  status: number;
}

export interface UserDetails {
  name: string;
  email: string;
}

export interface EmailSubscription {
  emailType: string;
  subscription: boolean;
}
