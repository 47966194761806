export interface Configuration {
  reputationThresholds: ReputationThreshold[];
  reputationVisible: boolean;
  insightVisible: boolean;
}

export interface ReputationThreshold {
  reputationThresholdId: number;
  name: string;
  lowerThreshold: number;
  symbolColor: string;
}

export const emptyConfiguration = () => ({
  reputationThresholds: [],
  reputationVisible: false,
  insightVisible: undefined
});

export const isConfigurationEmpty = (config: Configuration): boolean =>
  config.reputationThresholds.length === 0 &&
  config.reputationVisible === false &&
  config.insightVisible === undefined;

export interface ConfigurationSettings {
  key: string;
  value: string;
}
