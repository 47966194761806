import { ChartOptions, ChartType, LegendOptions } from 'chart.js';
import { draw } from 'patternomaly';
import { QuarterFrequency } from '../../interfaces/insights';
import { ProvidersCountries } from '../../interfaces/provider';
interface LabelInfo {
  name: string;
  displayColor: string;
}

const dataCampaignLabelsInfo: LabelInfo[] = [
  {
    name: 'Amazon',
    displayColor: '#3e88ef'
  },
  {
    name: 'Apple',
    displayColor: '#f14131'
  },
  {
    name: 'Auto Warranty',
    displayColor: '#fdba08'
  },
  {
    name: 'CCIRR',
    displayColor: '#7babf6'
  },
  {
    name: 'COVID-19',
    displayColor: '#ef7c71'
  },
  {
    name: 'DHS',
    displayColor: '#6ac684'
  },
  {
    name: 'Health Ins',
    displayColor: '#c2d2f2'
  },
  {
    name: 'Home Services',
    displayColor: '#eeb6b5'
  },
  {
    name: 'IRS',
    displayColor: '#fce49a'
  },
  {
    name: 'PSAP',
    displayColor: '#9999ff'
  },
  {
    name: 'SSA',
    displayColor: '#fec498'
  },
  {
    name: 'Student Loan',
    displayColor: '#b6e4e9'
  },
  {
    name: 'Tech Support',
    displayColor: '#fdf8e7'
  },
  {
    name: 'Test Call',
    displayColor: '#ebf6f1'
  },
  {
    name: 'Utility',
    displayColor: '#996633'
  },
  {
    name: 'Wangiri',
    displayColor: '#006600'
  },
  {
    name: 'Bank Scam',
    displayColor: '#33a954'
  },
  {
    name: 'Business Services',
    displayColor: '#fb6d08'
  },
  {
    name: 'Cable/Phone Impersonation',
    displayColor: '#43bec5'
  },
  {
    name: 'Debt Reduction',
    displayColor: '#fece4b'
  },
  {
    name: 'Employment Services',
    displayColor: '#ee9e60'
  },
  {
    name: 'Government Impersonation (non SSA)',
    displayColor: '#7cd2da'
  },
  {
    name: 'TDOS',
    displayColor: '#fcece9'
  },
  {
    name: 'Travel Scam',
    displayColor: '#990099'
  },
  {
    name: 'Sweepstakes',
    displayColor: '#edf3fd'
  }
];

export const dataProvider = (providerStats: any, admin?: boolean) => {
  if (providerStats) {
    let labels = Object.keys(providerStats);
    let datasets = [
      {
        type: 'line' as const,
        label: 'Avg NumberOfHops',
        data: labels.map((label: string) => {
          return providerStats[label].avgNumOfHops;
        }),
        borderColor: 'rgb(166, 166, 166)',
        borderWidth: 2,
        borderDash: [10],
        borderDashOffset: 1.0,
        fill: false
      },
      {
        type: 'bar' as const,
        label: 'Non Responsive',
        data: labels.map((label: string) => {
          return providerStats[label].numOfNoResponseHops;
        }),
        backgroundColor: 'rgb(221, 36, 20)'
      },
      {
        type: 'bar' as const,
        label: 'Downstream Non Responsive',
        data: labels.map((label: string) => {
          return providerStats[label].numOfDownstreamNoResponseHops;
        }),
        backgroundColor: 'rgb(255, 100, 0)'
      },
      {
        type: 'bar' as const,
        label: 'Origin',
        data: labels.map((label: string) => {
          return providerStats[label].numOfOriginHops;
        }),
        backgroundColor: 'rgb(6, 71, 218)'
      },
      {
        type: 'bar' as const,
        label: 'Downstream Origin',
        data: labels.map((label: string) => {
          return providerStats[label].numOfDownstreamOriginHops;
        }),
        backgroundColor: 'rgb(136, 172, 254)'
      },
      {
        type: 'bar' as const,
        label: 'Not Found',
        data: labels.map((label: string) => {
          return providerStats[label].numOfNotFoundHops;
        }),
        backgroundColor: 'rgb(247, 188, 0)'
      },
      {
        type: 'bar' as const,
        label: 'POE',
        data: labels.map((label: string) => {
          return providerStats[label].numOfUSPoEHops;
        }),
        backgroundColor: 'rgb(80, 189, 199)'
      },
      {
        type: 'bar' as const,
        label: 'POE & Downstream Origin',
        data: labels.map((label: string) => {
          return providerStats[label].numOfUSPoEAndDOrgHops;
        }),
        backgroundColor: [draw('diagonal', '#7eb0f7')]
      },
      {
        type: 'bar' as const,
        label: 'POE & Downstream Non Responsive',
        data: labels.map((label: string) => {
          return providerStats[label].numOfUSPoEAndDNRHops;
        }),
        backgroundColor: [draw('diagonal', '#f45909')]
      }
    ];

    if (!admin) {
      datasets.shift();
    }
    return {
      datasets,
      labels
    };
  }
};

export const dataTracebackVolume = (providerStats: any) => {
  if (providerStats) {
    let labels = Object.keys(providerStats);
    const datasets = [
      {
        label: 'No Response',
        data: labels.map((label: string) => {
          return providerStats[label].numOfNoResponseHops;
        }),
        backgroundColor: '#ea4235'
      },
      {
        label: 'Not Found',
        data: labels.map((label: string) => {
          return providerStats[label].numOfNotFoundHops;
        }),
        backgroundColor: '#fcbb06'
      },
      {
        label: 'Us Origin',
        data: labels.map((label: string) => {
          return providerStats[label].numOfUSOriginHops;
        }),
        backgroundColor: '#4286f5'
      },

      {
        label: 'Non-Us Origin',
        data: labels.map((label: string) => {
          return providerStats[label].numOfNonUsOriginHops;
        }),
        backgroundColor: '#34a853'
      }
    ];
    return {
      datasets,
      labels
    };
  }
};
export const dataAttestationVolume = (attestationStats: any) => {
  if (attestationStats) {
    const labels = Object.keys(attestationStats);
    const datasets = [
      {
        label: 'A',
        data: labels.map((label: string) => {
          return attestationStats[label].numberOfAAttestation;
        }),
        backgroundColor: '#4286f5'
      },
      {
        label: 'B',
        data: labels.map((label: string) => {
          return attestationStats[label].numberOfBAttestation;
        }),
        backgroundColor: '#fcbb06'
      },
      {
        label: 'C',
        data: labels.map((label: string) => {
          return attestationStats[label].numberOfCAttestation;
        }),
        backgroundColor: '#ea4235'
      }
    ];
    return {
      datasets,
      labels
    };
  }
};

export const dataCampaignLabels = (campaignStats: any) => {
  if (campaignStats) {
    const dates = Object.keys(campaignStats);
    const campaignLabels = dates
      .map((v: string) => Object.keys(campaignStats[v]))
      .flat()
      .filter((item, pos, a) => {
        return a.indexOf(item) == pos;
      });
    const datasets = campaignLabels.map((labelName: string) => ({
      label: labelName,
      data: dates.map((label: string) => {
        if (campaignStats[label][labelName]) {
          return campaignStats[label][labelName];
        }
      }),
      backgroundColor:
        dataCampaignLabelsInfo.find((v) => v.name === labelName)?.displayColor ||
        generateColorForLabel()
    }));
    return {
      datasets,
      labels: dates
    };
  }
};

const generateColorForLabel = (): string => {
  const randomColor = () => Math.floor(Math.random() * 256); // Generates a random number between 0 and 255
  const r = randomColor();
  const g = randomColor();
  const b = randomColor();
  return `rgba(${r}, ${g}, ${b}, 0.2)`; // Return the color in rgba format with some transparency
};

export const dataCallerCustomerAndAverageDailyTraffic = (stats: any) => {
  if (stats) {
    let labels = Object.keys(stats);
    const datasets = [
      {
        label: '10.001 - 100.000',
        data: labels.map((label: string) => {
          return stats[label]['10.001 - 100.000'];
        }),
        backgroundColor: '#4285f4',
        stack: 'Stack 1'
      },
      {
        label: '100.001 - 500.000',
        data: labels.map((label: string) => {
          return stats[label]['100.001 - 500.000'];
        }),
        backgroundColor: '#ea4336',
        stack: 'Stack 2'
      },
      {
        label: 'Less than 10.000',
        data: labels.map((label: string) => {
          return stats[label]['Less than 10.000'];
        }),
        backgroundColor: '#f7bc08',
        stack: 'Stack 3'
      },
      {
        label: 'More than 500.000',
        data: labels.map((label: string) => {
          return stats[label]['More than 500.000'];
        }),
        backgroundColor: '#48a753',
        stack: 'Stack 4'
      }
    ];
    return {
      datasets,
      labels
    };
  }
};

export const dataActionsOriginProviders = (originStats: any) => {
  if (originStats) {
    let labels = Object.keys(originStats);
    const datasets = [
      {
        label: 'Actions Taken by Providers',
        data: Object.values(originStats).map((item: any) => item),
        backgroundColor: labels.map((item: any) => {
          switch (item) {
            case 'Terminated calling party service':
              return '#f7bc05';
            case 'Asked calling party for information':
              return '#4186f4';
            case 'No action taken':
              return '#ea4235';
            case 'Warned calling party':
              return '#49a954';
            case 'Asked Upstream Voice Service Provider for information':
              return '#A4C3F6';
            case 'Warned Upstream Voice Service Provider':
              return '#A6DDAC';
            case 'Terminated Upstream Voice Service Provider':
              return '#F9D771';
            case 'Investigation':
              return '#50BDC6';
          }
        })
      }
    ];
    return {
      datasets,
      labels
    };
  }
};

export const dataOriginSigned = (originStats: any) => {
  if (originStats) {
    const labels = Object.keys(originStats);
    const datasets = [
      {
        data: Object.values(originStats).map((item: any) => item),
        backgroundColor: labels.map((item: any) => {
          switch (item) {
            case 'Signed by Origin':
              return '#4186f4';
            case 'Signed by Downstream':
              return '#f7bc05';
            case 'Not Signed':
              return '#ea4235';
          }
        })
      }
    ];
    return {
      datasets,
      labels
    };
  }
};

const getChartLegend = <TType extends ChartType = ChartType>(
  position: string,
  legend: LegendOptions<TType>
): LegendOptions<TType> => {
  switch (position) {
    case 'right':
      return { ...legend, position: 'right' as const };
    case 'left':
      return { ...legend, position: 'left' as const };
    case 'top':
      return { ...legend, position: 'top' as const };
    case 'bottom':
      return { ...legend, position: 'bottom' as const };
    default:
      return legend;
  }
};

export const setChartLegendPosition = <TType extends ChartType = ChartType>(
  position: string,
  options: ChartOptions<TType>
): ChartOptions<TType> => {
  const plugins = (options && (options as any).plugins) || {};
  return {
    ...options,
    plugins: {
      ...plugins,
      legend: getChartLegend(position, plugins.legend)
    }
  };
};

export const dataMapChart = (topoData: any, fetchedData?: ProvidersCountries[]) => {
  const data = fetchedData
    ?.map((item) => ({
      label: item.providerCountry,
      value: item.tracebacksPerCountry,
      feature: topoData.find(
        (d: any) =>
          item.providerCountry === d.properties.name ||
          (d.properties.name === 'United States of America' &&
            item.providerCountry === 'United States')
      )
    }))
    .filter((d: any) => d.feature);
  return {
    datasets: [
      {
        outline: topoData,
        label: 'World',
        hoverBackgroundColor: 'orange',
        borderColor: 'grey',
        data: data
      }
    ],
    labels: data?.map((item: any) => item.label)
  };
};
export const topologyUrl = 'https://unpkg.com/world-atlas/countries-50m.json';

export enum ProviderInfoType {
  NrProvider = '/nrProviderInfo',
  NfProvider = '/nfProviderInfo',
  NewProvider = '/newProviderInfo',
  FollowingProviders = '/followingProviders'
}

const colorsForInsightChart = (index: number) => {
  switch (index) {
    case 0:
      return '#4186f4';
    case 1:
      return '#ea4235';
    case 2:
      return '#f7bc05';
    case 3:
      return '#49a954';
  }
};
export const insightChartData = (insightData: QuarterFrequency[]) => {
  const datasets = insightData.map((insight: QuarterFrequency, index: number) => ({
    label: insight.quarterName,
    data: [insight.fiveRank, insight.fourRank, insight.threeRank, insight.twoRank, insight.oneRank],
    borderColor: colorsForInsightChart(index),
    tension: 0.4,
    fill: true
  }));
  return {
    labels: ['5', '4', '3', '2', '1'],
    datasets
  };
};
