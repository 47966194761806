import axios, { Canceler } from 'axios';
import { PostTracebackIncidentRequest } from '../../interfaces/incident';
import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import {
  getIncident,
  getIncidentCampaignNamesAction,
  getIncidentCampaignSources,
  getIncidentCampaignSummary,
  getIncidentSummary,
  getIncidents,
  postTracebackIncidents,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  getIncidentApiCall,
  getIncidentCampaignNamesApiCall,
  getIncidentCampaignSourcesApiCall,
  getIncidentCampaignSummaryApiCall,
  getIncidentsApiCall,
  getIncidentsSummaryApiCall,
  postTracebackIncidentApiCall,
  updateIncidentCampaignIds
} from './apiCalls';

// to canel previous request
const CancelToken = axios.CancelToken;
let cancelGetIncidentSummaryObject: Canceler;

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getReportObject = (incidentId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    getIncidentApiCall(incidentId).then((response) => {
      dispatch(getIncident(response.data));
      dispatch(setStatus(false, ''));
    });
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getIncidentsList =
  (params: Pagination, filterElements: FilterElement, campaignId: number) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      getIncidentsApiCall(params, filterElements)
        .then((response) => {
          dispatch(getIncidents([response.data, campaignId]));
          dispatch(setStatus(false, ''));
        })
        .catch((err) => {
          dispatch(getIncidents([[], campaignId]));
          dispatch(setStatus(false, err.message));
        });
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getIncidentSummaryObject =
  (
    sourceId: number,
    incidentCampaingId: number,
    week: number,
    params: Pagination,
    transcriptFilter?: string
  ) =>
  async (dispatch: any) => {
    if (cancelGetIncidentSummaryObject) cancelGetIncidentSummaryObject();
    dispatch(setStatus(true, ''));
    try {
      const response = await getIncidentsSummaryApiCall(
        new CancelToken((c) => {
          cancelGetIncidentSummaryObject = c;
        }),
        sourceId,
        incidentCampaingId,
        week,
        params,
        transcriptFilter
      );
      if (response.data) {
        dispatch(getIncidentSummary(response.data));
      }

      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getIncidentCampaignSourceList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    getIncidentCampaignSourcesApiCall().then((response) => {
      dispatch(getIncidentCampaignSources(response.data));
      dispatch(setStatus(false, ''));
    });
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const postTracebackIncidentList =
  (incidents: PostTracebackIncidentRequest) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await postTracebackIncidentApiCall(incidents);
      if (response && response.data) {
        dispatch(postTracebackIncidents(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const updateIncidentCampaign =
  (incidentCampaignId: number, tracebackCampaignId: number, requestorId: number) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await updateIncidentCampaignIds(
        incidentCampaignId,
        tracebackCampaignId,
        requestorId
      );
      dispatch(setStatus(false, ''));
      return !!(response && response.data);
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getIncidentCampaignSummaryObject = (id: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    getIncidentCampaignSummaryApiCall(id).then((response) => {
      if (response.data) {
        dispatch(getIncidentCampaignSummary(response.data));
      }

      dispatch(setStatus(false, ''));
    });
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getIncidentCampaignNames = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const campaignNames = await getIncidentCampaignNamesApiCall();
    if (campaignNames && campaignNames.data) {
      dispatch(getIncidentCampaignNamesAction(campaignNames.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
