import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Col } from 'reactstrap';
import { NotificationNewComment } from '../../../interfaces/comment';
import { TfHop } from '../../../interfaces/tfhop';
import { PersonalInfo } from '../../../interfaces/user';
import { getNotificationsNewCommentsList } from '../../../redux/comment/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import IconCounterDisplay from '../../IconCounterDisplay';
import { directedToValues } from '../../comments/AddHopComment';
import AddTfHopComment from './AddTfHopComment';
import HopComments from './TfHopComments';

interface IProps {
  tfhop: TfHop;
  isCallDestinationPopulated: boolean;
  user: PersonalInfo;
  getNotificationsNewCommentsList: Function;
  newCommentsNotificationList: NotificationNewComment[];
  notificationsCount: number;
}

const TfHopCommentsSection: React.FC<IProps> = ({
  tfhop,
  isCallDestinationPopulated,
  user,
  getNotificationsNewCommentsList,
  newCommentsNotificationList,
  notificationsCount
}) => {
  const { hash } = useLocation();
  const [visibleComments, setVisibleComments] = useState(
    hash !== '' || tfhop.comments?.length || tfhop.relatedComments?.length ? true : false
  );

  const handleCommentsDropDown = () => {
    setVisibleComments(!visibleComments);
  };

  useEffect(() => {
    getNotificationsNewCommentsList('tfhop', tfhop.tfhopId);
  }, [
    newCommentsNotificationList.length,
    tfhop.comments?.length,
    tfhop.relatedComments?.length,
    notificationsCount
  ]);

  return (
    <div>
      <div className="d-flex align-items-center pb-3 ">
        <h5 className="p-0 m-0 pe-1" onClick={handleCommentsDropDown} role="button">
          <i
            className={`${
              visibleComments ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'
            } pe-2 custom-blue`}
          ></i>
          Comments
        </h5>
        {newCommentsNotificationList.length > 0 && (
          <IconCounterDisplay count={newCommentsNotificationList.length} />
        )}
      </div>

      <div className="row me-3">
        <div
          className={`${
            visibleComments ? 'd-flex' : 'd-none'
          } flex-column highlighted-background me-0 ms-n3`}
        >
          {directedToValues(
            user.roleType,
            tfhop.tfhopProvider.name,
            tfhop.upstreamProvider ? tfhop.upstreamProvider.name : '',
            tfhop.downstreamProvider ? tfhop.downstreamProvider.name : '',
            false,
            tfhop.comments.some((comment) => comment.directedTo === 'oldDownstream')
          ).map((directedTo) => (
            <HopComments
              key={`hopSection-${directedTo.value}`}
              tfhop={tfhop}
              isCallDestinationPopulated={isCallDestinationPopulated}
              directedTo={directedTo}
            />
          ))}
          <div className="d-flex justify-content-center mb-5">
            <Col className="col-10 hop-detail-form-dark pb-3">
              <AddTfHopComment
                isCallDestinationPopulated={isCallDestinationPopulated}
                tfhopID={tfhop.tfhopId}
                tfhopProviderName={tfhop.tfhopProvider.name}
                upstreamProviderName={tfhop.upstreamProvider ? tfhop.upstreamProvider.name : ''}
                downstreamProviderName={
                  tfhop.downstreamProvider ? tfhop.downstreamProvider.name : ''
                }
              />
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    newCommentsNotificationList: sm.comment.newCommentsNotificationList,
    notificationsCount: sm.notification.notificationsCount
  };
};

const mapActionToProps = {
  getNotificationsNewCommentsList
};
export default connect(mapStateToProps, mapActionToProps)(TfHopCommentsSection);
