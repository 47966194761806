import React, { FC, useState } from 'react';
import { TfHop } from '../../interfaces/tfhop';
import { Traceforward } from '../../interfaces/traceforward';
import { PersonalInfo } from '../../interfaces/user';
import CallDestinationCard from './CallDestinationCard';
import CallDestinationDetailsRow from './CallDestinationDetailsRow';

interface IProps {
  tfhop: TfHop;
  updateTfHopObject: Function;
  traceforward: Traceforward;
  user: PersonalInfo;
}

const CallDestinationSection: FC<IProps> = ({ tfhop, updateTfHopObject, traceforward, user }) => {
  const [visible, setVisible] = useState(tfhop.status === 1 || tfhop.status === 7);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <div className="callSourceSection">
      <div className="d-flex align-items-center  pb-3">
        <h5 className="p-0 m-0" onClick={toggleVisibility} role="button">
          <i
            className={`${visible ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'} pe-2 custom-blue`}
          ></i>
          Call Destination
        </h5>
        {tfhop.status === 1 && (
          <div className="d-flex align-items-center ps-1">
            <i
              className="fa-solid fa-circle-exclamation text-danger"
              style={{ fontSize: '24px' }}
            ></i>
            <span className="ps-1">this is required</span>
          </div>
        )}
      </div>

      <div className={`${visible ? 'd-flex' : 'd-none'} flex-column ps-5`}>
        {tfhop.status === 1 && (
          <CallDestinationCard tfhop={tfhop} user={user} updateTfHopObject={updateTfHopObject} />
        )}
        <CallDestinationDetailsRow tfhop={tfhop} traceforward={traceforward} />
      </div>
    </div>
  );
};

export default CallDestinationSection;
